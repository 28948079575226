import { Button, Form, Input, List } from "antd";
import { MailOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { passwordForgot } from "../api/auth";

export const PasswordForgot = () => {
  const [t] = useTranslation("dashboard");

  const [input, setInput] = useState({
    credentials: {
      email: "",
    },
    messages: {
      errors: {},
      status: "",
    },
    hasVerified: false,
    countdown: 60,
  });

  const checkErrors = (response) => {
    return Object.keys(response || {}).some((key) =>
      ["email", "error"].includes(key),
    );
  };

  const emailOnChange = ({ target: { value } }) => {
    setInput({ ...input, credentials: { ...input.credentials, email: value } });
  };

  const passwordForgotOnClick = async () => {
    if (input.hasVerified) {
      return;
    }

    setInput((prev) => ({
      ...prev,
      hasVerified: true,
      countdown: 60,
    }));

    const response = await passwordForgot(input.credentials.email);

    if (checkErrors(response)) {
      setInput((prev) => ({
        ...prev,
        messages: {
          ...input.messages,
          status: "error",
          errors: response,
        },
      }));

      return;
    }

    setInput({
      ...input,
      messages: {
        ...input.messages,
        status: "success",
      },
    });

    const interval = setInterval(() => {
      setInput((prev) => {
        if (prev.countdown <= 1) {
          clearInterval(interval);
          return { ...prev, hasVerified: false, countdown: 0 };
        }
        return { ...prev, countdown: prev.countdown - 1 };
      });
    }, 1000);
  };

  return (
    <Form layout="vertical">
      <Form.Item>
        <Input
          placeholder={t("email")}
          value={input.credentials.email}
          onChange={emailOnChange}
          onPressEnter={passwordForgotOnClick}
          prefix={<MailOutlined />}
          status={input.messages.errors?.["email"] ? "error" : ""}
        />
      </Form.Item>
      {Object.keys(input.messages.errors || {}).length > 0 && (
        <Form.Item>
          <List
            dataSource={Object.values(input.messages.errors || {}).flat()}
            renderItem={(item) => (
              <List.Item style={{ color: "#9e0002" }}>
                {item.toString()}
              </List.Item>
            )}
            size="small"
          />
        </Form.Item>
      )}
      <Form.Item style={{ marginBottom: 0 }}>
        <Button
          onClick={passwordForgotOnClick}
          type="primary"
          loading={input.hasVerified}
          style={{ width: "100%" }}
        >
          {t("forgotPassword")}{" "}
          {input.countdown > 0 &&
            input.countdown !== 60 &&
            `(${input.countdown})`}
        </Button>
      </Form.Item>
    </Form>
  );
};
