import { Card, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Admin } from "./components/users/Admin";
import { Account } from "./components/users/Account";

export const Profile = () => {
  const [t] = useTranslation(["dashboard"]);

  const [input, setInput] = useState({
    navigation: "profile",
  });

  const navigationOnChange = (key) => {
    setInput({ ...input, navigation: key });
  };

  return (
    <Card
      bordered={false}
      size="small"
      style={{ padding: "0 2.7em", height: "100vh" }}
    >
      <Tabs
        activeKey={input.navigation}
        onChange={navigationOnChange}
        items={[
          {
            key: "profile",
            label: t("profile"),
            children: <Account />,
          },
          {
            key: "admin",
            label: t("admin"),
            children: <Admin />,
          },
        ]}
        tabPosition="top"
      />
    </Card>
  );
};
