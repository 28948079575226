import { Checkbox, Form } from "antd";
import { useState } from "react";

export const GroupColumns = ({
  filters,
  selectedFilters,
  columnFiltersOnChange,
}) => {
  const [input, setInput] = useState(
    {
      columns: (selectedFilters || []).reduce((acc, item) => {
        const [key, value] = item.split("|");
        (acc[key] = acc[key] || []).push(value);
        return acc;
      }, {}),
    } || {},
  );

  const groupOptionOnChange = (isChecked, groupParameter) => {
    setInput(() => {
      const columns = {
        ...input,
        columns: {
          ...input.columns,
          [groupParameter?.id]: isChecked
            ? groupParameter?.parameters?.map((parameter) => parameter.id)
            : [],
        },
      };

      columnFiltersOnChange(columns);

      return columns;
    });
  };

  const optionOnChange = (groupParameterId, parameterId) => {
    setInput(() => {
      const columns = {
        ...input,
        columns: {
          ...input.columns,
          [groupParameterId]: parameterId,
        },
      };

      columnFiltersOnChange(columns);

      return columns;
    });
  };

  return (
    <Form layout="vertical">
      {filters?.map((groupParameter) => (
        <div key={groupParameter?.id}>
          <Form.Item>
            <Checkbox
              indeterminate={
                input?.columns?.[groupParameter?.id]?.length > 0 &&
                input?.columns?.[groupParameter?.id]?.length <
                  groupParameter?.parameters?.length
              }
              onChange={({ target: { checked } }) =>
                groupOptionOnChange(checked, groupParameter)
              }
              checked={
                groupParameter?.parameters?.length ===
                input.columns?.[groupParameter?.id]?.length
              }
              style={{ width: "100%" }}
            >
              <b>{groupParameter.title}</b>
            </Checkbox>
            <Checkbox.Group
              value={input?.columns?.[groupParameter?.id] || []}
              options={groupParameter?.parameters?.map((parameter) => ({
                value: parameter.id,
                label: parameter.title,
              }))}
              style={{ flexDirection: "column", padding: "0 0 0 1.7em" }}
              onChange={(parameterId) =>
                optionOnChange(groupParameter?.id, parameterId)
              }
            />
          </Form.Item>
        </div>
      ))}
    </Form>
  );
};
