import React, { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Badge,
  Button,
  ConfigProvider,
  Divider,
  Flex,
  Popover,
  Space,
} from "antd";
import Layout, { Header } from "antd/lib/layout/layout";
import "./MainLayout.css";
import { usePreferences } from "../store/globalStore";
import {
  BellOutlined,
  LoginOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { hasAuthCookie, removeAuthCookie } from "../common/api/api";
import { navigatePath } from "../common/navigation/navigate";
import { clearStorage, getLanguage } from "../common/storage/storage";
import { supportedLanguages } from "../i18n";
import Language from "../components/Language";
import { fetchUserPreference } from "../pages/profile/components/users/api/userPreference";

export const MainLayout = () => {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation(["dashboard"]);
  const { category, language, setLanguage, authenticated, setAuthenticated } =
    usePreferences();

  useEffect(() => {
    let newLang = null;
    if (getLanguage() === null && authenticated) {
      const response = async () => {
        newLang = (await fetchUserPreference())?.language || "en";
      };
      response();
    } else {
      newLang = supportedLanguages.includes(language) ? language : "en";
    }

    setLanguage(newLang);
    i18n.changeLanguage(newLang);
    setAuthenticated(hasAuthCookie());
    navigate(navigatePath(lang, newLang, category));
  }, [language]);

  const locale = async () => {
    switch (language?.value) {
      case "bg":
        return (await import("antd/lib/locale/bg_BG")).default;
      case "cs":
        return (await import("antd/lib/locale/cs_CZ")).default;
      case "da":
        return (await import("antd/lib/locale/da_DK")).default;
      case "de":
        return (await import("antd/lib/locale/de_DE")).default;
      case "el":
        return (await import("antd/lib/locale/el_GR")).default;
      case "es":
        return (await import("antd/lib/locale/es_ES")).default;
      case "et":
        return (await import("antd/lib/locale/et_EE")).default;
      case "fi":
        return (await import("antd/lib/locale/fi_FI")).default;
      case "fr":
        return (await import("antd/lib/locale/fr_FR")).default;
      case "hr":
        return (await import("antd/lib/locale/hr_HR")).default;
      case "hu":
        return (await import("antd/lib/locale/hu_HU")).default;
      case "it":
        return (await import("antd/lib/locale/it_IT")).default;
      case "lt":
        return (await import("antd/lib/locale/lt_LT")).default;
      case "lv":
        return (await import("antd/lib/locale/lv_LV")).default;
      case "mt":
        return (await import("antd/lib/locale/mk_MK")).default;
      case "nl":
        return (await import("antd/lib/locale/nl_NL")).default;
      case "pl":
        return (await import("antd/lib/locale/pl_PL")).default;
      case "pt":
        return (await import("antd/lib/locale/pt_BR")).default;
      case "ro":
        return (await import("antd/lib/locale/ro_RO")).default;
      case "sk":
        return (await import("antd/lib/locale/sk_SK")).default;
      case "sl":
        return (await import("antd/lib/locale/sl_SI")).default;
      case "sv":
        return (await import("antd/lib/locale/sv_SE")).default;
      case "ua":
        return (await import("antd/lib/locale/uk_UA")).default;
      default:
        return (await import("antd/lib/locale/en_US")).default;
    }
  };

  const profileOnClick = () => {
    navigate(`/${language}/profile`);
  };

  const loginOnClick = () => {
    navigate(`/${language}/login`);
  };

  const logoutOnClick = () => {
    removeAuthCookie();
    clearStorage();
    setAuthenticated(false);
    navigate(`/${language}`);
  };

  return (
    <ConfigProvider locale={locale()}>
      <Layout style={{ height: "100vh", backgroundColor: "#fff" }}>
        <Header
          style={{
            display: "flex",
            alignItems: "space-between",
            backgroundColor: "#fff",
            width: "100%",
          }}
        >
          <Space style={{ justifyContent: "flex-end", flexGrow: "1" }}>
            {authenticated ? null : <Language />}
            {authenticated && (
              <Popover
                placement="bottomRight"
                content={
                  <Flex gap="middle" vertical>
                    <div>Notification 1</div>
                    <div>Notification 2</div>
                    <div>Notification 3</div>
                    <div>Notification 4</div>
                    <div>Notification 5</div>
                  </Flex>
                }
              >
                <Badge count={5}>
                  <Avatar icon={<BellOutlined />} />
                </Badge>
              </Popover>
            )}
            <Popover
              placement="bottomRight"
              content={
                <Flex gap="small" vertical>
                  {authenticated ? (
                    <>
                      <Button
                        type="link"
                        icon={<UserOutlined />}
                        style={{ justifyContent: "left" }}
                        onClick={profileOnClick}
                      >
                        {t("profile")}
                      </Button>
                      <Button
                        type="link"
                        icon={<LogoutOutlined />}
                        style={{ justifyContent: "left" }}
                        onClick={logoutOnClick}
                      >
                        {t("logout")}
                      </Button>
                    </>
                  ) : (
                    <Button
                      type="link"
                      icon={<LoginOutlined />}
                      style={{ justifyContent: "left" }}
                      onClick={loginOnClick}
                    >
                      {t("login")}
                    </Button>
                  )}
                  {authenticated && (
                    <>
                      <Divider style={{ margin: 0 }} />
                      <Button
                        type="link"
                        icon={<UserOutlined />}
                        style={{ justifyContent: "left" }}
                        onClick={() => navigate(`/${lang}`)}
                      >
                        {t("dashboard")}
                      </Button>
                      <Button
                        type="link"
                        icon={<UserOutlined />}
                        style={{ justifyContent: "left" }}
                        onClick={() =>
                          navigate(
                            `/${lang}/catman/catalog/category/navigation`,
                          )
                        }
                      >
                        {t("catalog")}
                      </Button>
                      <Button
                        type="link"
                        icon={<UserOutlined />}
                        style={{ justifyContent: "left" }}
                        onClick={() =>
                          navigate(`/${lang}/catman/catalog/product/brand`)
                        }
                      >
                        {t("products")}
                      </Button>
                    </>
                  )}
                </Flex>
              }
            >
              <Avatar icon={<UserOutlined />} />
            </Popover>
          </Space>
        </Header>
        <Outlet />
      </Layout>
    </ConfigProvider>
  );
};
