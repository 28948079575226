import { getLanguage, setLanguage } from "../../common/storage/storage";
import { hasAuthCookie } from "../../common/api/api";

export const preferencesActions = {
  LANGUAGE: "language",
  AUTHENTICATED: "authenticated",
};

export const preferencesInitialState = {
  language: getLanguage() || "en",
  authenticated: hasAuthCookie(),
};

export const preferencesReducer = (state, action) => {
  switch (action.type) {
    case preferencesActions.LANGUAGE:
      setLanguage(action.language);
      return {
        ...state,
        language: action.language,
      };
    case preferencesActions.AUTHENTICATED:
      return {
        ...state,
        authenticated: action.authenticated,
      };
    default:
      return state;
  }
};
