import { Button, Divider, Form, Input, Select, Space, Typography } from "antd";
import { countries } from "../../../../components/country";
import { useTranslation } from "react-i18next";
import { SaveOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { usePreferences } from "../../../../store/globalStore";
import { languages } from "../../../../components/lang";
import { supportedLanguages } from "../../../../i18n";
import { passwordForgot } from "../../../auth/login/api/auth";
import {
  fetchUserPreference,
  persistUserPreference,
} from "./api/userPreference";

export const Account = () => {
  const [t] = useTranslation(["dashboard"]);
  const { language, setLanguage } = usePreferences();

  const [input, setInput] = useState({
    profile: {
      name: null,
      email: null,
      country: null,
      language: language,
      organization: null,
    },
    countries: countries.map((item) => ({
      value: item.code,
      label: `${item.flag} ${item.country}`,
    })),
    languages: languages
      .filter((language) => supportedLanguages.includes(language.code))
      .map((item) => ({
        value: item.code,
        label: `${item.flag} ${item.language}`,
      })),
    status: {
      changePassword: false,
    },
    countdown: 60,
  });

  useEffect(() => {
    const init = async () => {
      setInput({
        ...input,
        profile: (await fetchUserPreference()) || {},
      });
    };

    init();
  }, []);

  const nameOnChange = ({ target: { value } }) => {
    setInput({ ...input, profile: { ...input.profile, name: value } });
  };

  const nameSaveOnClick = async () => {
    setInput({
      ...input,
      profile: { ...input.profile, name: input.profile.name },
    });
    await persistUserPreference({ name: input.profile.name });
  };

  const countryOnSelect = async (value) => {
    setInput({ ...input, profile: { ...input.profile, country: value } });
    await persistUserPreference({ country: value });
  };

  const languageOnSelect = async (value) => {
    setInput({ ...input, profile: { ...input.profile, language: value } });
    setLanguage(value);
    await persistUserPreference({ language: value });
  };

  const passwordResetOnClick = async () => {
    if (input.status.changePassword) {
      return;
    }

    setInput({
      ...input,
      status: { ...input.status, changePassword: true },
    });

    await passwordForgot(input.profile.email);

    const interval = setInterval(() => {
      setInput((prev) => {
        if (prev.countdown <= 1) {
          clearInterval(interval);
          return {
            ...prev,
            status: { ...prev.status, changePassword: false },
            countdown: 60,
          };
        }
        return { ...prev, countdown: prev.countdown - 1 };
      });
    }, 1000);
  };

  return (
    <Form layout="vertical">
      <Divider orientation="left" orientationMargin="0">
        {t("personalInformation")}
      </Divider>
      <Form.Item label={t("name")}>
        <Space.Compact style={{ width: "100%" }}>
          <Input value={input.profile.name} onChange={nameOnChange} />
          <Button
            onClick={nameSaveOnClick}
            type="primary"
            icon={<SaveOutlined />}
          />
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("country")}>
        <Select
          value={input.profile.country}
          onChange={countryOnSelect}
          options={input.countries}
          showSearch
          optionFilterProp="label"
          virtual
        />
      </Form.Item>
      <Form.Item label={t("language")}>
        <Select
          value={input.profile.language}
          onChange={languageOnSelect}
          options={input.languages}
          showSearch
          virtual
        />
      </Form.Item>
      <Divider orientation="left" orientationMargin="0">
        {t("account")}
      </Divider>
      {input.profile.organization && (
        <Form.Item label={t("organization")} layout="horizontal">
          <Typography.Text>{input.profile.organization}</Typography.Text>
        </Form.Item>
      )}
      <Form.Item label={t("changePassword")} layout="horizontal">
        <Button
          onClick={passwordResetOnClick}
          type="link"
          style={{ paddingLeft: 0 }}
          loading={input.status.changePassword}
        >
          {input.profile.email}{" "}
          {input.status.changePassword ? `(${t("emailSent")})` : ""}
        </Button>
      </Form.Item>
    </Form>
  );
};
