import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import { login, register } from "../api/auth";
import { setAuthCookie } from "../../../../common/api/api";
import { Button, Form, Input, List, Space } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  KeyOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { PasswordForgot } from "./PasswordForgot";
import { usePreferences } from "../../../../store/globalStore";
import { fetchUserPreference } from "../../../profile/components/users/api/userPreference";
import { setLanguage } from "../../../../common/storage/storage";

export const Login = () => {
  const [t] = useTranslation("dashboard");
  const { lang } = useParams();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { setAuthenticated } = usePreferences();

  const [input, setInput] = useState({
    credentials: {
      email: "",
      username: "",
      password: "",
    },
    messages: {
      errors: {},
      status: "",
    },
    hasForgotPassword: false,
  });

  const checkErrors = (response) => {
    return Object.keys(response || {}).some((key) =>
      ["email", "password", "error"].includes(key),
    );
  };

  const emailOnChange = ({ target: { value } }) => {
    setInput({ ...input, credentials: { ...input.credentials, email: value } });
  };

  const passwordOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      credentials: { ...input.credentials, password: value },
    });
  };

  const forgotPasswordOnClick = () => {
    setInput({ ...input, hasForgotPassword: true });
  };

  const loginOnClick = async () => {
    setInput({
      ...input,
      messages: { ...input.messages, status: "loading", errors: {} },
    });

    const response =
      input.type === t("register")
        ? await register(
            input.credentials.email,
            input.credentials.username,
            input.credentials.password,
          )
        : await login(input.credentials.email, input.credentials.password);

    if (checkErrors(response)) {
      setInput({
        ...input,
        messages: {
          ...input.messages,
          status: "error",
          errors: response,
        },
      });
      return;
    }

    if (Object.keys(response || {}).includes("access_token")) {
      setInput({
        ...input,
        messages: {
          ...input.messages,
          status: "success",
          errors: {},
        },
      });

      setAuthCookie(response?.access_token, response?.expires_in);
      setAuthenticated(true);
      const userLanguage = (await fetchUserPreference())?.language;
      setLanguage(userLanguage);
      i18n.changeLanguage(userLanguage);
      navigate(`/${userLanguage}`);
    }
  };

  return (
    <>
      {input.hasForgotPassword ? (
        <PasswordForgot />
      ) : (
        <Form layout="vertical">
          <Form.Item>
            <Input
              placeholder={t("email")}
              value={input.credentials.email}
              onChange={emailOnChange}
              onPressEnter={loginOnClick}
              prefix={<MailOutlined />}
              status={input.messages.errors?.["email"] ? "error" : ""}
            />
          </Form.Item>
          <Form.Item>
            <Input.Password
              placeholder={t("password")}
              value={input.credentials.password}
              onChange={passwordOnChange}
              onPressEnter={loginOnClick}
              prefix={<KeyOutlined />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              minLength={8}
              status={input.messages.errors?.["password"] ? "error" : ""}
            />
          </Form.Item>
          {Object.keys(input.messages.errors || {}).length > 0 && (
            <Form.Item>
              <List
                dataSource={Object.values(input.messages.errors || {}).flat()}
                renderItem={(item) => (
                  <List.Item style={{ color: "#9e0002" }}>
                    {item.toString()}
                  </List.Item>
                )}
                size="small"
              />
            </Form.Item>
          )}
          <Form.Item>
            <Space
              align="center"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <Button
                type="link"
                onClick={forgotPasswordOnClick}
                style={{ paddingRight: 0 }}
              >
                {t("forgotPassword")}
              </Button>
            </Space>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              onClick={loginOnClick}
              type="primary"
              loading={input.messages.status === "loading"}
              style={{ width: "100%" }}
            >
              {t("login")}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
