import React, { createContext, useContext, useReducer } from "react";
import { rootInitialState, rootReducer } from "./reducers/root";
import { preferencesActions } from "./reducers/preferences";

const StoreContext = createContext({
  state: {},
  dispatch: () => {},
});

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    rootReducer,
    null,
    () => rootInitialState,
  );

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);

export const usePreferences = () => {
  const { state, dispatch } = useStore();

  return {
    language: state.preferences.language,
    setLanguage: (language) =>
      dispatch({ type: preferencesActions.LANGUAGE, language }),
    authenticated: state.preferences.authenticated,
    setAuthenticated: (authenticated) =>
      dispatch({ type: preferencesActions.AUTHENTICATED, authenticated }),
  };
};
