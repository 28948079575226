export const countries = [
  {
    code: "af",
    flag: "🇦🇫",
    country: "Afghanistan",
  },
  {
    code: "al",
    flag: "🇦🇱",
    country: "Albania",
  },
  {
    code: "dz",
    flag: "🇩🇿",
    country: "Algeria",
  },
  {
    code: "as",
    flag: "🇦🇸",
    country: "American Samoa",
  },
  {
    code: "ad",
    flag: "🇦🇩",
    country: "Andorra",
  },
  {
    code: "ao",
    flag: "🇦🇴",
    country: "Angola",
  },
  {
    code: "ai",
    flag: "🇦🇮",
    country: "Anguilla",
  },
  {
    code: "aq",
    flag: "🇦🇶",
    country: "Antarctica",
  },
  {
    code: "ag",
    flag: "🇦🇬",
    country: "Antigua and Barbuda",
  },
  {
    code: "ar",
    flag: "🇦🇷",
    country: "Argentina",
  },
  {
    code: "am",
    flag: "🇦🇲",
    country: "Armenia",
  },
  {
    code: "aw",
    flag: "🇦🇼",
    country: "Aruba",
  },
  {
    code: "au",
    flag: "🇦🇺",
    country: "Australia",
  },
  {
    code: "at",
    flag: "🇦🇹",
    country: "Austria",
  },
  {
    code: "az",
    flag: "🇦🇿",
    country: "Azerbaijan",
  },
  {
    code: "bs",
    flag: "🇧🇸",
    country: "Bahamas",
  },
  {
    code: "bh",
    flag: "🇧🇭",
    country: "Bahrain",
  },
  {
    code: "bd",
    flag: "🇧🇩",
    country: "Bangladesh",
  },
  {
    code: "bb",
    flag: "🇧🇧",
    country: "Barbados",
  },
  {
    code: "by",
    flag: "🇧🇾",
    country: "Belarus",
  },
  {
    code: "be",
    flag: "🇧🇪",
    country: "Belgium",
  },
  {
    code: "bz",
    flag: "🇧🇿",
    country: "Belize",
  },
  {
    code: "bj",
    flag: "🇧🇯",
    country: "Benin",
  },
  {
    code: "bm",
    flag: "🇧🇲",
    country: "Bermuda",
  },
  {
    code: "bt",
    flag: "🇧🇹",
    country: "Bhutan",
  },
  {
    code: "bo",
    flag: "🇧🇴",
    country: "Bolivia",
  },
  {
    code: "bq",
    flag: "🇧🇶",
    country: "Bonaire, Sint Eustatius and Saba",
  },
  {
    code: "ba",
    flag: "🇧🇦",
    country: "Bosnia and Herzegovina",
  },
  {
    code: "bw",
    flag: "🇧🇼",
    country: "Botswana",
  },
  {
    code: "bv",
    flag: "🇧🇻",
    country: "Bouvet Island",
  },
  {
    code: "br",
    flag: "🇧🇷",
    country: "Brazil",
  },
  {
    code: "io",
    flag: "🇮🇴",
    country: "British Indian Ocean Territory",
  },
  {
    code: "bn",
    flag: "🇧🇳",
    country: "Brunei Darussalam",
  },
  {
    code: "bg",
    flag: "🇧🇬",
    country: "Bulgaria",
  },
  {
    code: "bf",
    flag: "🇧🇫",
    country: "Burkina Faso",
  },
  {
    code: "bi",
    flag: "🇧🇮",
    country: "Burundi",
  },
  {
    code: "cv",
    flag: "🇨🇻",
    country: "Cabo Verde",
  },
  {
    code: "kh",
    flag: "🇰🇭",
    country: "Cambodia",
  },
  {
    code: "cm",
    flag: "🇨🇲",
    country: "Cameroon",
  },
  {
    code: "ca",
    flag: "🇨🇦",
    country: "Canada",
  },
  {
    code: "ky",
    flag: "🇰🇾",
    country: "Cayman Islands",
  },
  {
    code: "cf",
    flag: "🇨🇫",
    country: "Central African Republic",
  },
  {
    code: "td",
    flag: "🇹🇩",
    country: "Chad",
  },
  {
    code: "cl",
    flag: "🇨🇱",
    country: "Chile",
  },
  {
    code: "cn",
    flag: "🇨🇳",
    country: "China",
  },
  {
    code: "cx",
    flag: "🇨🇽",
    country: "Christmas Island",
  },
  {
    code: "cc",
    flag: "🇨🇨",
    country: "Cocos (Keeling) Islands",
  },
  {
    code: "co",
    flag: "🇨🇴",
    country: "Colombia",
  },
  {
    code: "km",
    flag: "🇰🇲",
    country: "Comoros",
  },
  {
    code: "cg",
    flag: "🇨🇬",
    country: "Congo",
  },
  {
    code: "cd",
    flag: "🇨🇩",
    country: "Congo, Democratic Republic of the",
  },
  {
    code: "ck",
    flag: "🇨🇰",
    country: "Cook Islands",
  },
  {
    code: "cr",
    flag: "🇨🇷",
    country: "Costa Rica",
  },
  {
    code: "hr",
    flag: "🇭🇷",
    country: "Croatia",
  },
  {
    code: "cu",
    flag: "🇨🇺",
    country: "Cuba",
  },
  {
    code: "cw",
    flag: "🇨🇼",
    country: "Curaçao",
  },
  {
    code: "cy",
    flag: "🇨🇾",
    country: "Cyprus",
  },
  {
    code: "cz",
    flag: "🇨🇿",
    country: "Czech Republic",
  },
  {
    code: "dk",
    flag: "🇩🇰",
    country: "Denmark",
  },
  {
    code: "dj",
    flag: "🇩🇯",
    country: "Djibouti",
  },
  {
    code: "dm",
    flag: "🇩🇲",
    country: "Dominica",
  },
  {
    code: "do",
    flag: "🇩🇴",
    country: "Dominican Republic",
  },
  {
    code: "ec",
    flag: "🇪🇨",
    country: "Ecuador",
  },
  {
    code: "eg",
    flag: "🇪🇬",
    country: "Egypt",
  },
  {
    code: "sv",
    flag: "🇸🇻",
    country: "El Salvador",
  },
  {
    code: "gq",
    flag: "🇬🇶",
    country: "Equatorial Guinea",
  },
  {
    code: "er",
    flag: "🇪🇷",
    country: "Eritrea",
  },
  {
    code: "ee",
    flag: "🇪🇪",
    country: "Estonia",
  },
  {
    code: "sz",
    flag: "🇸🇿",
    country: "Eswatini",
  },
  {
    code: "et",
    flag: "🇪🇹",
    country: "Ethiopia",
  },
  {
    code: "fk",
    flag: "🇫🇰",
    country: "Falkland Islands",
  },
  {
    code: "fo",
    flag: "🇫🇴",
    country: "Faroe Islands",
  },
  {
    code: "fj",
    flag: "🇫🇯",
    country: "Fiji",
  },
  {
    code: "fi",
    flag: "🇫🇮",
    country: "Finland",
  },
  {
    code: "fr",
    flag: "🇫🇷",
    country: "France",
  },
  {
    code: "gf",
    flag: "🇬🇫",
    country: "French Guiana",
  },
  {
    code: "pf",
    flag: "🇵🇫",
    country: "French Polynesia",
  },
  {
    code: "tf",
    flag: "🇹🇫",
    country: "French Southern Territories",
  },
  {
    code: "ga",
    flag: "🇬🇦",
    country: "Gabon",
  },
  {
    code: "gm",
    flag: "🇬🇲",
    country: "Gambia",
  },
  {
    code: "ge",
    flag: "🇬🇪",
    country: "Georgia",
  },
  {
    code: "de",
    flag: "🇩🇪",
    country: "Germany",
  },
  {
    code: "gh",
    flag: "🇬🇭",
    country: "Ghana",
  },
  {
    code: "gi",
    flag: "🇬🇮",
    country: "Gibraltar",
  },
  {
    code: "gr",
    flag: "🇬🇷",
    country: "Greece",
  },
  {
    code: "gl",
    flag: "🇬🇱",
    country: "Greenland",
  },
  {
    code: "gd",
    flag: "🇬🇩",
    country: "Grenada",
  },
  {
    code: "gp",
    flag: "🇬🇵",
    country: "Guadeloupe",
  },
  {
    code: "gu",
    flag: "🇬🇺",
    country: "Guam",
  },
  {
    code: "gt",
    flag: "🇬🇹",
    country: "Guatemala",
  },
  {
    code: "gg",
    flag: "🇬🇬",
    country: "Guernsey",
  },
  {
    code: "gn",
    flag: "🇬🇳",
    country: "Guinea",
  },
  {
    code: "gw",
    flag: "🇬🇼",
    country: "Guinea-Bissau",
  },
  {
    code: "gy",
    flag: "🇬🇾",
    country: "Guyana",
  },
  {
    code: "ht",
    flag: "🇭🇹",
    country: "Haiti",
  },
  {
    code: "hm",
    flag: "🇭🇲",
    country: "Heard Island and McDonald Islands",
  },
  {
    code: "va",
    flag: "🇻🇦",
    country: "Holy See",
  },
  {
    code: "hn",
    flag: "🇭🇳",
    country: "Honduras",
  },
  {
    code: "hk",
    flag: "🇭🇰",
    country: "Hong Kong",
  },
  {
    code: "hu",
    flag: "🇭🇺",
    country: "Hungary",
  },
  {
    code: "is",
    flag: "🇮🇸",
    country: "Iceland",
  },
  {
    code: "in",
    flag: "🇮🇳",
    country: "India",
  },
  {
    code: "id",
    flag: "🇮🇩",
    country: "Indonesia",
  },
  {
    code: "ir",
    flag: "🇮🇷",
    country: "Iran",
  },
  {
    code: "iq",
    flag: "🇮🇶",
    country: "Iraq",
  },
  {
    code: "ie",
    flag: "🇮🇪",
    country: "Ireland",
  },
  {
    code: "im",
    flag: "🇮🇲",
    country: "Isle of Man",
  },
  {
    code: "il",
    flag: "🇮🇱",
    country: "Israel",
  },
  {
    code: "it",
    flag: "🇮🇹",
    country: "Italy",
  },
  {
    code: "jm",
    flag: "🇯🇲",
    country: "Jamaica",
  },
  {
    code: "jp",
    flag: "🇯🇵",
    country: "Japan",
  },
  {
    code: "je",
    flag: "🇯🇪",
    country: "Jersey",
  },
  {
    code: "jo",
    flag: "🇯🇴",
    country: "Jordan",
  },
  {
    code: "kz",
    flag: "🇰🇿",
    country: "Kazakhstan",
  },
  {
    code: "ke",
    flag: "🇰🇪",
    country: "Kenya",
  },
  {
    code: "ki",
    flag: "🇰🇮",
    country: "Kiribati",
  },
  {
    code: "kp",
    flag: "🇰🇵",
    country: "Korea, Democratic People's Republic of",
  },
  {
    code: "kr",
    flag: "🇰🇷",
    country: "Korea, Republic of",
  },
  {
    code: "kw",
    flag: "🇰🇼",
    country: "Kuwait",
  },
  {
    code: "kg",
    flag: "🇰🇬",
    country: "Kyrgyzstan",
  },
  {
    code: "la",
    flag: "🇱🇦",
    country: "Lao People's Democratic Republic",
  },
  {
    code: "lv",
    flag: "🇱🇻",
    country: "Latvia",
  },
  {
    code: "lb",
    flag: "🇱🇧",
    country: "Lebanon",
  },
  {
    code: "ls",
    flag: "🇱🇸",
    country: "Lesotho",
  },
  {
    code: "lr",
    flag: "🇱🇷",
    country: "Liberia",
  },
  {
    code: "ly",
    flag: "🇱🇾",
    country: "Libya",
  },
  {
    code: "li",
    flag: "🇱🇮",
    country: "Liechtenstein",
  },
  {
    code: "lt",
    flag: "🇱🇹",
    country: "Lithuania",
  },
  {
    code: "lu",
    flag: "🇱🇺",
    country: "Luxembourg",
  },
  {
    code: "mo",
    flag: "🇲🇴",
    country: "Macao",
  },
  {
    code: "mg",
    flag: "🇲🇬",
    country: "Madagascar",
  },
  {
    code: "mw",
    flag: "🇲🇼",
    country: "Malawi",
  },
  {
    code: "my",
    flag: "🇲🇾",
    country: "Malaysia",
  },
  {
    code: "mv",
    flag: "🇲🇻",
    country: "Maldives",
  },
  {
    code: "ml",
    flag: "🇲🇱",
    country: "Mali",
  },
  {
    code: "mt",
    flag: "🇲🇹",
    country: "Malta",
  },
  {
    code: "mh",
    flag: "🇲🇭",
    country: "Marshall Islands",
  },
  {
    code: "mq",
    flag: "🇲🇶",
    country: "Martinique",
  },
  {
    code: "mr",
    flag: "🇲🇦",
    country: "Mauritania",
  },
  {
    code: "mu",
    flag: "🇲🇺",
    country: "Mauritius",
  },
  {
    code: "yt",
    flag: "🇾🇹",
    country: "Mayotte",
  },
  {
    code: "mx",
    flag: "🇲🇽",
    country: "Mexico",
  },
  {
    code: "fm",
    flag: "🇫🇲",
    country: "Micronesia",
  },
  {
    code: "md",
    flag: "🇲🇩",
    country: "Moldova",
  },
  {
    code: "mc",
    flag: "🇲🇨",
    country: "Monaco",
  },
  {
    code: "mn",
    flag: "🇲🇳",
    country: "Mongolia",
  },
  {
    code: "me",
    flag: "🇲🇪",
    country: "Montenegro",
  },
  {
    code: "ms",
    flag: "🇲🇸",
    country: "Montserrat",
  },
  {
    code: "ma",
    flag: "🇲🇦",
    country: "Morocco",
  },
  {
    code: "mz",
    flag: "🇲🇿",
    country: "Mozambique",
  },
  {
    code: "mm",
    flag: "🇲🇲",
    country: "Myanmar",
  },
  {
    code: "na",
    flag: "🇳🇦",
    country: "Namibia",
  },
  {
    code: "nr",
    flag: "🇳🇷",
    country: "Nauru",
  },
  {
    code: "np",
    flag: "🇳🇵",
    country: "Nepal",
  },
  {
    code: "nl",
    flag: "🇳🇱",
    country: "Netherlands",
  },
  {
    code: "nc",
    flag: "🇳🇨",
    country: "New Caledonia",
  },
  {
    code: "nz",
    flag: "🇳🇿",
    country: "New Zealand",
  },
  {
    code: "ni",
    flag: "🇳🇮",
    country: "Nicaragua",
  },
  {
    code: "ne",
    flag: "🇳🇪",
    country: "Niger",
  },
  {
    code: "ng",
    flag: "🇳🇬",
    country: "Nigeria",
  },
  {
    code: "nu",
    flag: "🇳🇺",
    country: "Niue",
  },
  {
    code: "nf",
    flag: "🇳🇫",
    country: "Norfolk Island",
  },
  {
    code: "mp",
    flag: "🇲🇵",
    country: "Northern Mariana Islands",
  },
  {
    code: "no",
    flag: "🇳🇴",
    country: "Norway",
  },
  {
    code: "om",
    flag: "🇴🇲",
    country: "Oman",
  },
  {
    code: "pk",
    flag: "🇵🇰",
    country: "Pakistan",
  },
  {
    code: "pw",
    flag: "🇵🇼",
    country: "Palau",
  },
  {
    code: "ps",
    flag: "🇵🇸",
    country: "Palestine, State of",
  },
  {
    code: "pa",
    flag: "🇵🇦",
    country: "Panama",
  },
  {
    code: "pg",
    flag: "🇵🇬",
    country: "Papua New Guinea",
  },
  {
    code: "py",
    flag: "🇵🇾",
    country: "Paraguay",
  },
  {
    code: "pe",
    flag: "🇵🇪",
    country: "Peru",
  },
  {
    code: "ph",
    flag: "🇵🇭",
    country: "Philippines",
  },
  {
    code: "pn",
    flag: "🇵🇳",
    country: "Pitcairn",
  },
  {
    code: "pl",
    flag: "🇵🇱",
    country: "Poland",
  },
  {
    code: "pt",
    flag: "🇵🇹",
    country: "Portugal",
  },
  {
    code: "pr",
    flag: "🇵🇷",
    country: "Puerto Rico",
  },
  {
    code: "qa",
    flag: "🇶🇦",
    country: "Qatar",
  },
  {
    code: "re",
    flag: "🇷🇪",
    country: "Réunion",
  },
  {
    code: "ro",
    flag: "🇷🇴",
    country: "Romania",
  },
  {
    code: "ru",
    flag: "🇷🇺",
    country: "Russian Federation",
  },
  {
    code: "rw",
    flag: "🇷🇼",
    country: "Rwanda",
  },
  {
    code: "bl",
    flag: "🇧🇱",
    country: "Saint Barthélemy",
  },
  {
    code: "sh",
    flag: "🇸🇭",
    country: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    code: "kn",
    flag: "🇰🇳",
    country: "Saint Kitts and Nevis",
  },
  {
    code: "lc",
    flag: "🇱🇨",
    country: "Saint Lucia",
  },
  {
    code: "mf",
    flag: "🇲🇫",
    country: "Saint Martin (French part)",
  },
  {
    code: "pm",
    flag: "🇵🇲",
    country: "Saint Pierre and Miquelon",
  },
  {
    code: "vc",
    flag: "🇻🇨",
    country: "Saint Vincent and the Grenadines",
  },
  {
    code: "ws",
    flag: "🇼🇸",
    country: "Samoa",
  },
  {
    code: "sm",
    flag: "🇸🇲",
    country: "San Marino",
  },
  {
    code: "st",
    flag: "🇸🇹",
    country: "Sao Tome and Principe",
  },
  {
    code: "sa",
    flag: "🇸🇦",
    country: "Saudi Arabia",
  },
  {
    code: "sn",
    flag: "🇸🇳",
    country: "Senegal",
  },
  {
    code: "rs",
    flag: "🇷🇸",
    country: "Serbia",
  },
  {
    code: "sc",
    flag: "🇸🇨",
    country: "Seychelles",
  },
  {
    code: "sl",
    flag: "🇸🇱",
    country: "Sierra Leone",
  },
  {
    code: "sg",
    flag: "🇸🇬",
    country: "Singapore",
  },
  {
    code: "sx",
    flag: "🇸🇽",
    country: "Sint Maarten (Dutch part)",
  },
  {
    code: "sk",
    flag: "🇸🇰",
    country: "Slovakia",
  },
  {
    code: "si",
    flag: "🇸🇮",
    country: "Slovenia",
  },
  {
    code: "sb",
    flag: "🇸🇧",
    country: "Solomon Islands",
  },
  {
    code: "so",
    flag: "🇸🇴",
    country: "Somalia",
  },
  {
    code: "za",
    flag: "🇿🇦",
    country: "South Africa",
  },
  {
    code: "gs",
    flag: "🇬🇸",
    country: "South Georgia and the South Sandwich Islands",
  },
  {
    code: "ss",
    flag: "🇸🇸",
    country: "South Sudan",
  },
  {
    code: "es",
    flag: "🇪🇸",
    country: "Spain",
  },
  {
    code: "lk",
    flag: "🇱🇰",
    country: "Sri Lanka",
  },
  {
    code: "sd",
    flag: "🇸🇩",
    country: "Sudan",
  },
  {
    code: "sr",
    flag: "🇸🇷",
    country: "Suriname",
  },
  {
    code: "sj",
    flag: "🇸🇯",
    country: "Svalbard and Jan Mayen",
  },
  {
    code: "sz",
    flag: "🇸🇿",
    country: "Eswatini",
  },
  {
    code: "se",
    flag: "🇸🇪",
    country: "Sweden",
  },
  {
    code: "ch",
    flag: "🇨🇭",
    country: "Switzerland",
  },
  {
    code: "sy",
    flag: "🇸🇾",
    country: "Syrian Arab Republic",
  },
  {
    code: "tw",
    flag: "🇹🇼",
    country: "Taiwan",
  },
  {
    code: "tj",
    flag: "🇹🇯",
    country: "Tajikistan",
  },
  {
    code: "tz",
    flag: "🇹🇿",
    country: "Tanzania",
  },
  {
    code: "th",
    flag: "🇹🇭",
    country: "Thailand",
  },
  {
    code: "tl",
    flag: "🇹🇱",
    country: "Timor-Leste",
  },
  {
    code: "tg",
    flag: "🇹🇬",
    country: "Togo",
  },
  {
    code: "tk",
    flag: "🇹🇰",
    country: "Tokelau",
  },
  {
    code: "to",
    flag: "🇹🇴",
    country: "Tonga",
  },
  {
    code: "tt",
    flag: "🇹🇹",
    country: "Trinidad and Tobago",
  },
  {
    code: "tn",
    flag: "🇹🇳",
    country: "Tunisia",
  },
  {
    code: "tr",
    flag: "🇹🇷",
    country: "Turkey",
  },
  {
    code: "tm",
    flag: "🇹🇲",
    country: "Turkmenistan",
  },
  {
    code: "tc",
    flag: "🇹🇨",
    country: "Turks and Caicos Islands",
  },
  {
    code: "tv",
    flag: "🇹🇻",
    country: "Tuvalu",
  },
  {
    code: "ug",
    flag: "🇺🇬",
    country: "Uganda",
  },
  {
    code: "ua",
    flag: "🇺🇦",
    country: "Ukraine",
  },
  {
    code: "ae",
    flag: "🇦🇪",
    country: "United Arab Emirates",
  },
  {
    code: "gb",
    flag: "🇬🇧",
    country: "United Kingdom",
  },
  {
    code: "us",
    flag: "🇺🇸",
    country: "United States",
  },
  {
    code: "uy",
    flag: "🇺🇾",
    country: "Uruguay",
  },
  {
    code: "uz",
    flag: "🇺🇿",
    country: "Uzbekistan",
  },
  {
    code: "vu",
    flag: "🇻🇺",
    country: "Vanuatu",
  },
  {
    code: "ve",
    flag: "🇻🇪",
    country: "Venezuela",
  },
  {
    code: "vn",
    flag: "🇻🇳",
    country: "Viet Nam",
  },
  {
    code: "wf",
    flag: "🇼🇫",
    country: "Wallis and Futuna",
  },
  {
    code: "eh",
    flag: "🇪🇭",
    country: "Western Sahara",
  },
  {
    code: "ye",
    flag: "🇾🇪",
    country: "Yemen",
  },
  {
    code: "zm",
    flag: "🇿🇲",
    country: "Zambia",
  },
  {
    code: "zw",
    flag: "🇿🇼",
    country: "Zimbabwe",
  },
];
