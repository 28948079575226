const persistToStorage = (key, value) => localStorage.setItem(key, value);

const getFromStorage = (key) => localStorage.getItem(key);

export const clearStorage = () => localStorage.clear();

export const setLanguage = (language) => {
  persistToStorage("language", language);
};

export const getLanguage = () => getFromStorage("language");
