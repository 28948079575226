import { Button, Checkbox, Collapse, Form, InputNumber, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const GroupParameter = ({
  data,
  filters,
  selectedFilters,
  filtersOnChange,
}) => {
  const [t] = useTranslation("dashboard");

  const [input, setInput] = useState({
    filtered: {},
  });

  const modifyFilterWithCount = (products, filters) => {
    products?.forEach((product) => {
      filters?.forEach((groupParameter) => {
        groupParameter?.parameters?.forEach((parameter) => {
          if (product.key === `${groupParameter.id}|${parameter.id}`) {
            if (parameter.type === "TEXT") {
              parameter.options?.forEach((option) => {
                option.total = (option.total || 0) + 1;
              });
            } else {
              parameter.total = (parameter.total || 0) + 1;
            }
          }
        });
      });
    });
  };

  const filterOnChange = (value, type, groupParameterId, parameterId) => {
    setInput(() => {
      const data = {
        ...input,
        filtered: {
          ...input.filtered,
          [`${groupParameterId}|${parameterId}`]: {
            type,
            options:
              type === "TEXT" ? value?.map((option) => option?.title) : null,
            checked: type === "LOGICAL" ? value : null,
            value:
              type === "NUMBER"
                ? {
                    minValue: value[0],
                    maxValue: value[1],
                  }
                : null,
          },
        },
      };

      filtersOnChange(data.filtered);

      return data;
    });
  };

  const items = (data, filters, selectedFilters) => {
    // modifyFilterWithCount(data, filters);

    return filters?.map((groupParameter) => ({
      key: groupParameter?.id,
      label: <b>{groupParameter?.title}</b>,
      children: (
        <Form layout="vertical">
          {groupParameter?.parameters?.map((parameter) => (
            <Form.Item
              key={`${groupParameter.id}|${parameter?.id}`}
              label={
                parameter?.type === "TEXT"
                  ? parameter?.title
                  : `${parameter?.title} (${parameter?.total || 0})`
              }
            >
              {parameter?.type === "TEXT" && (
                <Checkbox.Group
                  value={parameter?.options
                    ?.filter((option) =>
                      selectedFilters[
                        `${groupParameter.id}|${parameter.id}`
                      ]?.options?.includes(option.title),
                    )
                    ?.map((option) => option.id)}
                  options={parameter?.options?.map((option) => ({
                    value: option.id,
                    label: `${option.title} (${option.total || 0})`,
                    // disabled:
                    //   option.total === null ||
                    //   option.total === undefined ||
                    //   option.total === 0,
                  }))}
                  onChange={(value) =>
                    filterOnChange(
                      parameter?.options?.filter((option) =>
                        value?.includes(option.id),
                      ),
                      "TEXT",
                      groupParameter.id,
                      parameter.id,
                    )
                  }
                  style={{ flexDirection: "column" }}
                />
              )}
              {parameter?.type === "LOGICAL" && (
                <Checkbox
                  onChange={({ taget: { checked } }) =>
                    filterOnChange(
                      checked,
                      "LOGICAL",
                      groupParameter.id,
                      parameter.id,
                    )
                  }
                  disabled={
                    parameter.total === null ||
                    parameter.total === undefined ||
                    parameter.total === 0
                  }
                >
                  {parameter?.title} ({parameter?.total})
                </Checkbox>
              )}
              {parameter?.type === "NUMBER" && (
                <Space.Compact style={{ width: "100%" }}>
                  <InputNumber
                    value={parameter?.minValue}
                    onChange={(value) =>
                      filterOnChange(
                        [
                          value,
                          input.filtered?.[
                            `${groupParameter.id}|${parameter.id}`
                          ]?.value?.maxValue || parameter.maxValue,
                        ],
                        "NUMBER",
                        groupParameter.id,
                        parameter.id,
                      )
                    }
                    min={parameter?.minValue}
                    max={parameter?.maxValue}
                    disabled={
                      parameter.total === null ||
                      parameter.total === undefined ||
                      parameter.total === 0
                    }
                    style={{ width: "50%" }}
                  />
                  <InputNumber
                    value={parameter?.maxValue}
                    onChange={(value) =>
                      filterOnChange(
                        [
                          input.filtered?.[
                            `${groupParameter.id}|${parameter.id}`
                          ]?.value?.minValue || parameter.minValue,
                          value,
                        ],
                        "NUMBER",
                        groupParameter.id,
                        parameter.id,
                      )
                    }
                    min={parameter?.minValue}
                    max={parameter?.maxValue}
                    disabled={
                      parameter.total === null ||
                      parameter.total === undefined ||
                      parameter.total === 0
                    }
                    style={{ width: "50%" }}
                  />
                </Space.Compact>
              )}
            </Form.Item>
          ))}
        </Form>
      ),
    }));
  };

  const clearFiltersOnClick = () => {
    setInput({
      ...input,
      filtered: {},
    });

    filtersOnChange({});
  };

  return (
    <>
      <Space
        style={{
          width: "100%",
          justifyContent: "flex-end",
          padding: ".3em 0",
        }}
      >
        <Button
          icon={<DeleteOutlined />}
          type="link"
          iconPosition="end"
          onClick={clearFiltersOnClick}
        >
          {t("resetFilters")}
        </Button>
      </Space>
      <Collapse items={items(data, filters, selectedFilters)} accordion />
    </>
  );
};
