import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout, { Content } from "antd/lib/layout/layout";
import { Button, Cascader, Space, Table } from "antd";
import {
  FilterOutlined,
  FunnelPlotOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Card } from "antd/lib";
import "./Home.css";
import { useParams } from "react-router-dom";
import Sider from "antd/lib/layout/Sider";
import { fetchCategories } from "./api/category";
import { fetchProducts } from "./api/product";
import { fetchFilters } from "./api/filter";
import { GroupParameter } from "./components/filter/parameters/GroupParameter";
import { GroupColumns } from "./components/filter/columns/GroupColumns";

export default function Home() {
  const PRODUCTS = "products";
  const CHARACTERISTICS = "characteristics";
  const ADDITIONAL_INFO = "additionalInfo";

  const [t] = useTranslation(["dashboard"]);
  const { lang } = useParams();

  const [input, setInput] = useState({
    filtersCollapsed: true,
    columnsCollapsed: true,
    activeTab: PRODUCTS,
    categories: [],
    categoryId: null,
    table: {
      loading: false,
      columns: [],
      selectedFilters: [],
      filters: [],
      filteredColumns: [],
      data: [],
      filteredData: [],
    },
  });

  useEffect(() => {
    const init = async () => {
      let filters = [];
      let products = [];

      if (input.categoryId !== null) {
        const categoryId = input.categoryId?.at(-1)?.split("|")[1];
        filters = await fetchFilters(categoryId, lang);
        products = await fetchProducts(categoryId, lang);
      }

      const categories = (await fetchCategories(lang)) || [];

      setInput({
        ...input,
        categories: Array.isArray(categories) ? categories : [],
        table: {
          ...input.table,
          loading: false,
          columns: products.length > 0 ? columns(filters) : [],
          data: tableData(products),
          filters: filters,
        },
      });
    };

    init();
  }, [lang]);

  const chars = 15;

  const columns = (filters) => {
    return [
      {
        key: "ean",
        dataIndex: "ean",
        title: t("ean"),
        width: t("ean").length * chars,
      },
      {
        key: "mpn",
        dataIndex: "mpn",
        title: t("mpn"),
        width: t("mpn").length * chars,
      },
      {
        key: "brand",
        dataIndex: "brand",
        title: t("brand"),
        width: t("brand").length * chars,
      },
      {
        key: "trademark",
        dataIndex: "trademark",
        title: t("trademark"),
        width: t("trademark").length * chars,
      },
      ...filters
        ?.sort((a, b) => a.orderIndex - b.orderIndex)
        ?.flatMap((groupParam) =>
          groupParam?.parameters
            ?.sort((a, b) => a.orderIndex - b.orderIndex)
            .map((param) => ({
              key: `${groupParam.id}|${param.id}`,
              dataIndex: `${groupParam.id}|${param.id}`,
              title: param.title,
              width: param.title.length * chars,
            })),
        ),
    ];
  };

  const tableData = (products) => {
    return products.map((product) => {
      return {
        key: product.id,
        ean: product.ean,
        mpn: product.mpn,
        brand: product.brand,
        trademark: product.trademark,
        ...product.parameters,
      };
    });
  };

  const columnFiltersOnChange = (value) => {
    setInput({
      ...input,
      table: {
        ...input.table,
        filteredColumns: Object.entries(value.columns || {}).flatMap(
          ([key, values]) => values.map((value) => `${key}|${value}`),
        ),
      },
    });
  };

  const onTabChange = (key) => {
    setInput({
      ...input,
      filtersCollapsed: key === PRODUCTS ? input.filtersCollapsed : true,
      activeTab: key,
    });
  };

  const collapseFiltersSidebar = () => {
    setInput({
      ...input,
      filtersCollapsed: !input.filtersCollapsed,
    });
  };

  const collapseColumnsSidebar = () => {
    setInput({
      ...input,
      columnsCollapsed: !input.columnsCollapsed,
    });
  };

  const categoryOnChange = async (value) => {
    if (!value || value?.length === 0) {
      setInput({
        categoryId: null,
        table: {
          loading: false,
          columns: [],
          data: [],
          filters: [],
        },
      });
      return;
    }

    setInput({
      ...input,
      categoryId: value,
      table: {
        ...input.table,
        loading: true,
      },
    });

    const categoryId = value?.at(-1)?.split("|")[1];
    const filters = await fetchFilters(categoryId, lang);
    const products = await fetchProducts(categoryId, lang);

    setInput({
      ...input,
      categoryId: value,
      table: {
        ...input.table,
        columns: products.length > 0 ? columns(filters) : [],
        data: tableData(products),
        filters: filters,
        loading: false,
      },
    });
  };

  const filtersOnChange = (value) => {
    setInput({
      ...input,
      table: {
        ...input.table,
        selectedFilters: value,
        filteredData: [],
      },
    });
  };

  return (
    <>
      <Content style={{ overflow: "auto" }}>
        <Card
          bordered={false}
          style={{
            padding: "0 1em",
          }}
        >
          <Cascader
            expandTrigger="hover"
            value={input.categoryId}
            options={input.categories}
            onChange={categoryOnChange}
            placeholder={t("categoryName")}
            showSearch={{
              filter: (input, data) =>
                data.some(
                  (option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >
                    -1,
                ),
            }}
            style={{ width: "100%" }}
          />
        </Card>
        {input.categoryId && (
          <Layout>
            <Sider
              collapsible
              trigger={null}
              breakpoint="lg"
              collapsedWidth={0}
              collapsed={input.filtersCollapsed}
              width={300}
              style={{
                background: "#fff",
                padding: input.filtersCollapsed ? "0" : "0 0 0 2.5em",
                overflow: "auto",
                height: "calc(100vh - 11em)",
                scrollbarWidth: "thin",
                scrollbarGutter: "stable",
              }}
            >
              {!input.filtersCollapsed && (
                <>
                  <GroupParameter
                    data={input.table.data}
                    filters={input.table.filters}
                    selectedFilters={input.table.selectedFilters}
                    filtersOnChange={filtersOnChange}
                  />
                </>
              )}
            </Sider>
            <Layout style={{ background: "#fff" }}>
              <Card
                bordered={false}
                size="small"
                tabList={[
                  {
                    key: PRODUCTS,
                    tab: t(PRODUCTS),
                  },
                  {
                    key: CHARACTERISTICS,
                    tab: t(CHARACTERISTICS),
                  },
                  {
                    key: ADDITIONAL_INFO,
                    tab: t(ADDITIONAL_INFO),
                  },
                ]}
                activeTabKey={input.activeTab}
                onTabChange={onTabChange}
                style={{
                  padding: "0 2em",
                }}
              >
                {input.activeTab === PRODUCTS && (
                  <>
                    <Space
                      style={{
                        paddingBottom: ".7em",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        onClick={collapseFiltersSidebar}
                        icon={
                          input.filtersCollapsed ? (
                            <MenuUnfoldOutlined />
                          ) : (
                            <MenuFoldOutlined />
                          )
                        }
                        style={{
                          width: 45,
                          height: 32,
                        }}
                      />
                      <Button
                        onClick={collapseColumnsSidebar}
                        icon={
                          input.columnsCollapsed ? (
                            <FilterOutlined />
                          ) : (
                            <FunnelPlotOutlined />
                          )
                        }
                        style={{
                          width: 45,
                          height: 32,
                        }}
                      />
                    </Space>
                    <Table
                      size="middle"
                      columns={input.table.columns?.map((col) => ({
                        ...col,
                        hidden:
                          !["ean", "mpn", "brand", "trademark"].includes(
                            col.key,
                          ) && !input.table.filteredColumns?.includes(col.key),
                      }))}
                      dataSource={input.table.data}
                      scroll={{ x: "max-content", y: 400 }}
                      pagination={{
                        pageSize: 10,
                      }}
                      loading={input.table.loading}
                      className={"table-striped-rows"}
                    />
                  </>
                )}
              </Card>
            </Layout>
            <Sider
              collapsible
              trigger={null}
              breakpoint="lg"
              collapsedWidth={0}
              collapsed={input.columnsCollapsed}
              width={300}
              style={{
                background: "#fff",
                padding: input.columnsCollapsed ? "0" : "0 2.5em 0 0",
                overflow: "auto",
                height: "calc(100vh - 11em)",
                scrollbarWidth: "thin",
                scrollbarGutter: "stable",
              }}
            >
              {!input.columnsCollapsed && (
                <>
                  <GroupColumns
                    filters={input.table.filters}
                    selectedFilters={input.table.filteredColumns}
                    columnFiltersOnChange={columnFiltersOnChange}
                  />
                </>
              )}
            </Sider>
          </Layout>
        )}
      </Content>
    </>
  );
}
