import { Button, Card, Flex, Form, Input } from "antd";
import React, { useState } from "react";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  KeyOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { passwordReset } from "./api/password";

export const PasswordReset = () => {
  const [t] = useTranslation("dashboard");
  const { lang, token } = useParams();
  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const [input, setInput] = useState({
    credentials: {
      token: token,
      email: queryParams.get("email"),
      password: "",
      password_confirmation: "",
    },
    messages: {
      errors: {},
      status: "",
    },
  });

  const checkErrors = (response) => {
    return Object.keys(response || {}).some((key) =>
      ["password", "password_confirmation", "errors"].includes(key),
    );
  };

  const passwordOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      credentials: { ...input.credentials, password: value },
    });
  };

  const passwordConfirmOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      credentials: { ...input.credentials, password_confirmation: value },
    });
  };

  const passwordResetOnClick = async () => {
    setInput({
      ...input,
      messages: { ...input.messages, status: "loading", errors: {} },
    });

    const { token, email, password, password_confirmation } = input.credentials;
    const response = await passwordReset(
      token,
      email,
      password,
      password_confirmation,
    );

    if (checkErrors(response)) {
      setInput({
        ...input,
        messages: {
          ...input.messages,
          status: "error",
          errors: response?.errors,
        },
      });

      return;
    }

    navigate(`/${lang}/login`);
  };

  return (
    <Flex justify="center">
      <Card style={{ minWidth: "30em" }}>
        <Form layout="vertical">
          <Form.Item>
            <Input.Password
              placeholder={t("password")}
              value={input.credentials.password}
              onChange={passwordOnChange}
              onPressEnter={passwordResetOnClick}
              prefix={<KeyOutlined />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              minLength={8}
              status={input.messages.errors?.["password"] ? "error" : ""}
            />
          </Form.Item>
          <Form.Item>
            <Input.Password
              placeholder={t("confirmPassword")}
              value={input.credentials.password_confirmation}
              onChange={passwordConfirmOnChange}
              onPressEnter={passwordResetOnClick}
              prefix={<KeyOutlined />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              minLength={8}
              status={
                input.messages.errors?.["password_confirmation"] ? "error" : ""
              }
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              onClick={passwordResetOnClick}
              type="primary"
              loading={input.messages.status === "loading"}
              style={{ width: "100%" }}
            >
              {t("resetPassword")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Flex>
  );
};
