import { getAPI, postAPI } from "../../../../../common/api/api";

const PATH = "user";

export const fetchRoles = async (userId) => getAPI(`${PATH}/role`);

export const fetchUserRoles = async (userId) =>
  getAPI(`${PATH}/${userId}/role`);

export const persistUserRoles = async (userId, roles) =>
  postAPI(`${PATH}/${userId}/role`, { roles });
