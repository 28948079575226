import { Button, Form, Select, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { fetchRoles, fetchUserRoles, persistUserRoles } from "./api/role";
import { fetchUsers } from "./api/user";
import { SaveOutlined } from "@ant-design/icons";

export const Admin = () => {
  const [t] = useTranslation(["dashboard"]);

  const [input, setInput] = useState({
    user: null,
    users: [],
    role: [],
    roles: [],
  });

  useEffect(() => {
    const init = async () => {
      const users = (await fetchUsers())?.users || [];
      const roles = (await fetchRoles())?.roles || [];

      setInput({
        ...input,
        users: users.map((user) => ({
          value: user.id,
          label: `${user.name} (${user.email})`,
        })),
        roles: roles.map((role) => ({
          value: role.id,
          label: role.description,
          name: role.name,
        })),
      });
    };

    init();
  }, []);

  const usersOnChange = async (userId) => {
    const roles = (await fetchUserRoles(userId))?.roles || [];

    setInput({
      ...input,
      user: userId,
      role: roles.map((role) => role.id),
    });
  };

  const rolesOnChange = (value) => {
    setInput({ ...input, role: value });
  };

  const saveOnClick = async () => {
    await persistUserRoles(input.user, input.role);
  };

  return (
    <>
      {input.users?.length > 0 && (
        <Form layout="vertical">
          <Form.Item>
            <Select
              value={input.user}
              options={input.users}
              onChange={usersOnChange}
              placeholder={t("user")}
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item>
            <Select
              value={input.role}
              options={input.roles}
              onChange={rolesOnChange}
              placeholder={t("role")}
              showSearch
              allowClear
              mode="multiple"
              maxTagCount="responsive"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              optionRender={({ data }) => (
                <Tooltip mouseLeaveDelay={0} placement="top" title={data.label}>
                  <Typography.Text>{data.label}</Typography.Text>
                </Tooltip>
              )}
            />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={saveOnClick}
              type="primary"
              icon={<SaveOutlined />}
            >
              {t("save")}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
