export const languages = [
  {
    code: "bg",
    flag: "🇧🇬",
    language: "Български",
  },
  {
    code: "cs",
    flag: "🇨🇿",
    language: "Čeština",
  },
  {
    code: "da",
    flag: "🇩🇰",
    language: "Dansk",
  },
  {
    code: "de",
    flag: "🇩🇪",
    language: "Deutsch",
  },
  {
    code: "el",
    flag: "🇬🇷",
    language: "Ελληνικά",
  },
  {
    code: "en",
    flag: "🇺🇸",
    language: "English",
  },
  {
    code: "es",
    flag: "🇪🇸",
    language: "Español",
  },
  {
    code: "et",
    flag: "🇪🇪",
    language: "Eesti",
  },
  {
    code: "fi",
    flag: "🇫🇮",
    language: "Suomi",
  },
  {
    code: "fr",
    flag: "🇫🇷",
    language: "Français",
  },
  {
    code: "hr",
    flag: "🇭🇷",
    language: "Hrvatski",
  },
  {
    code: "hu",
    flag: "🇭🇺",
    language: "Magyar",
  },
  {
    code: "it",
    flag: "🇮🇹",
    language: "Italiano",
  },
  {
    code: "lt",
    flag: "🇱🇹",
    language: "Lietuvių",
  },
  {
    code: "lv",
    flag: "🇱🇻",
    language: "Latviešu",
  },
  {
    code: "mt",
    flag: "🇲🇾",
    language: "Malti",
  },
  {
    code: "nl",
    flag: "🇳🇱",
    language: "Nederlands",
  },
  {
    code: "pl",
    flag: "🇵🇱",
    language: "Polski",
  },
  {
    code: "pt",
    flag: "🇵🇹",
    language: "Português",
  },
  {
    code: "ro",
    flag: "🇷🇴",
    language: "Română",
  },
  {
    code: "sk",
    flag: "🇸🇰",
    language: "Slovak",
  },
  {
    code: "sl",
    flag: "🇸🇮",
    language: "Slovenski",
  },
  {
    code: "sv",
    flag: "🇸🇪",
    language: "Svenska",
  },
  {
    code: "ua",
    flag: "🇺🇦",
    language: "Українська",
  },
];
