import { postAPI } from "../../../../common/api/api";

const PATH = "user";

export const register = async (email, name, password) =>
  postAPI(`${PATH}/register`, { email, name, password });

export const login = async (email, password) =>
  postAPI(`${PATH}/login`, { email, password });

export const resendVerification = async (email, password) =>
  postAPI(`${PATH}/email/resend`, { email, password });

export const passwordForgot = async (email) =>
  postAPI(`${PATH}/password/forgot`, { email });

export const logout = async () => postAPI(`${PATH}/logout`);
