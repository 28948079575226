import Layout, { Content } from "antd/lib/layout/layout";
import { Button, Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingOutlined,
  TagOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Category from "./components/category/Category";
import Products from "./components/products/Products";
import Settings from "./components/settings/Settings";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { catmanSection } from "../../routes/routes";

export default function Catman() {
  const [t] = useTranslation("catman");
  const params = useParams();
  const navigate = useNavigate();

  const [input, setInput] = useState({
    collapsed: true,
    navigation: params.group || "category",
    navigationKeys: [],
  });

  const items = [
    {
      key: "catalog",
      icon: <ShopOutlined />,
      label: t("catalog"),
      children: [
        {
          key: "category",
          icon: <TagOutlined />,
          label: t("category"),
        },
        {
          key: "product",
          icon: <ShoppingOutlined />,
          label: t("product"),
        },
      ],
    },
    {
      key: "settings",
      icon: <SettingOutlined />,
      label: t("settings"),
    },
  ];

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };

  const onNavigationKeysChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => input.navigationKeys.indexOf(key) === -1,
    );

    if (currentOpenKey !== undefined) {
      const levelKeys = getLevelKeys(items);

      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setInput({
        ...input,
        navigationKeys: openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      });
    } else {
      setInput({
        ...input,
        navigationKeys: openKeys,
      });
    }
  };

  useEffect(() => {
    setInput({
      ...input,
      navigation: params.group || "category",
      navigationKeys: [params.section || "catalog", params.group || "category"],
    });
  }, [params.group, params.section]);

  const menuOnSelect = ({ key }) => {
    setInput({ ...input, navigation: key });
    navigate(catmanSection(params) + `/${key}`);
  };

  return (
    <>
      <Layout>
        <Sider
          trigger={null}
          collapsedWidth={0}
          collapsible
          theme="light"
          collapsed={input.collapsed}
        >
          <div className="demo-logo-vertical" />
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[input.navigation]}
            openKeys={input.navigationKeys}
            onSelect={menuOnSelect}
            onOpenChange={onNavigationKeysChange}
            items={items}
          />
        </Sider>
        <Layout style={{ padding: "0 50px", background: "#fff" }}>
          <Button
            type="text"
            icon={
              input.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
            }
            onClick={() => setInput({ ...input, collapsed: !input.collapsed })}
            style={{
              justifyContent: "start",
            }}
          />
          <Content>
            {input.navigation === "category" && <Category />}
            {input.navigation === "product" && <Products />}
            {input.navigation === "settings" && <Settings />}
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
