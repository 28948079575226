const api = async (method, path, headers, body) => {
  const res = await fetch(`https://requrix.com/api/${path}`, {
    method: method,
    redirect: "follow",
    credentials: "include",
    // mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getCookie("token")}`,
      ...headers,
    },
    body: body !== undefined ? JSON.stringify(body) : null,
  });

  try {
    return await res.json();
  } catch (e) {
    console.log("Failed to call API", e);
    return {};
  }
};

export const getAPI = async (path, headers) => {
  return api("GET", path, headers);
};

export const postAPI = async (path, body) => {
  return api("POST", path, null, body);
};

export const deleteAPI = async (path, body) => {
  return api("DELETE", path, null, body);
};

export const setAuthCookie = (token, expires) => {
  setCookie("token", token, expires);
};

export const getAuthCookie = () => getCookie("token");

export const removeAuthCookie = () => {
  removeCookie("token");
};

export const hasAuthCookie = () => !hasCookieRemoved("token");

const setCookie = (name, value, period) => {
  if (
    value === undefined ||
    value === null ||
    (typeof value === "string" &&
      value instanceof String &&
      value?.trim() === "")
  ) {
    return;
  }

  document.cookie = `${name}=${value || ""}; expires=${period}; path=/`;
};

const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document?.cookie?.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
};

const removeCookie = (name) => {
  document.cookie = `${name}=; expires=-99999999;`;
};

const hasCookieRemoved = (name) => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const hasAuth = cookie.startsWith(`${name}=`);
    const auth = hasAuth ? cookie.split("=")?.[1] : null;
    if (auth === undefined || auth === null || auth === "") {
      return true;
    }
  }

  return false;
};
